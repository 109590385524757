<template>
    <Alert :message="error" v-if="error" />
    <div class="right-content">
        <PeopleDashNav :access="access" />
        <div class="page-content">
            <sub-nav-loading v-if="!documents" />
            <div v-else class="right-sub-nav fixed" v-if="access">
                <div class="nav-row">
                    <div class="sub-nav-left"></div>
                    <div class="sub-nav-right">
                        <router-link class="add-icon-link" :to="{ name: 'InviteInspectorModal' }">
                            <p>Invite Inspector</p>
                            <fa class="add-icon" icon="plus-circle" />
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="list-container">
                <!-- Loading -->
                <list-loading v-if="!documents" />

                <!-- empty list -->
                <div class="no-list vertical-center" v-if="documents && documents.length == 0">
                    <div class="icon">
                        <fa icon="envelope-open-text" />
                    </div>
                    <p>No invitations found</p>
                </div>

                <!-- documents list -->
                <div v-if="documents && documents.length > 0" class="table-row-container">
                    <div v-for="doc in documents" :key="doc.id">
                        <!-- document row -->
                        <div class="table-row no-link">
                            <div class="row-left">
                                <div class="title no-img-title">{{ doc.email }}</div>
                                <div class="description" v-if="doc.status == 'Pending'">
                                    <div
                                        class="invite"
                                        @click="copyUrl(doc.id), copySuccess(doc.id)"
                                        :class="{ isCopied: copied }"
                                        v-if="copiedId == '' || copiedId == doc.id"
                                    >
                                        <span id="invite-url">copy invite url</span>
                                        <div class="copy-icon">
                                            <fa v-if="!copied" icon="clone" />
                                            <fa v-else icon="check" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row-right">
                                <div class="help-text">
                                    <p>{{ doc.status }}</p>
                                </div>
                                <div
                                    class="help-text"
                                    v-if="doc.status == 'Pending' && doc.createdAt"
                                >
                                    <p>Sent {{ moment(doc.createdAt.toDate()).format("MMMM Do YYYY") }}</p>
                                </div>

                                <div class="actions" v-if="doc.status == 'Pending'">
                                    <div class="actions-icon-btn" @click="deleteInvite(doc.id)">
                                        <fa icon="trash" />
                                    </div>
                                </div>

                                <div class="actions" v-if="doc.status == 'Accepted'">
                                    <div class="actions-icon-btn" @click="editInvite(doc.id)">
                                        <fa icon="pen" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <transition name="fade" mode="out-in">
        <router-view />
    </transition>
</template>

<script>
// Components
import PeopleDashNav from "../../components/people/PeopleDashNav";
import PageLoading from "../../components/PageLoading.vue";
import Alert from "@/components/Alert";

// Composables
import account from "../../composables/account";
import getCollection from "../../composables/getCollection";
import useCollection from "@/composables/useCollection";
import { projectFirestore } from "../../firebase/config";
import getDocument from "../../composables/getDocument";
// Vue / Other
import { computed, ref, watchEffect } from "@vue/runtime-core";
import EnvUtils from "@/misc/env.js";
import { useMeta } from "vue-meta";
import moment from "moment";
import ListLoading from "../../components/ListLoading.vue";
import SubNavLoading from "../../components/SubNavLoading.vue";
import EditInspectorLocation from "./EditInspectorLocation.vue";
import { useRouter } from "vue-router";

export default {
    name: "InspectorInvitationsDash",
    components: {
        PeopleDashNav,
        Alert,
        PageLoading,
        ListLoading,
        SubNavLoading,
        EditInspectorLocation,
    },
    setup() {
        useMeta({ title: "Invitations" });
        const error = ref(null);
        const { companyId, userDoc } = account();
        const showEdit = ref(false);
        const inspectorID = ref("");
        const router = useRouter();
        // Data
        const db = projectFirestore;

        const inspectorRef = db
            .collection("companies")
            .doc(companyId)
            .collection("inspector");
        const { error: getErr, documents } = getCollection(inspectorRef);

        // Delete invitation
        const deleteRef = db
            .collection("companies")
            .doc(companyId)
            .collection("inspector");
        const { error: deleteErr, deleteDoc } = useCollection(deleteRef);

        const deleteInvite = async (doc) => {
            await deleteDoc(doc);
        };

        const empty = computed(() => {
            return documents.value.length == 0;
        });

        // Access
        const access = computed(() => {
            if (userDoc.value) {
                return (
                    userDoc.value.role == "Admin" ||
                    userDoc.value.manages.length > 0
                );
            }
        });

        const copyUrl = (id) => {
            console.log(EnvUtils.PORTAL);
            let url =
                EnvUtils.PORTAL +
                "/inspectorinvitation/" +
                companyId +
                "/inspector/" +
                id;
            navigator.clipboard.writeText(url);
        };

        const copied = ref(false);
        const copiedId = ref("");

        const copySuccess = (id) => {
            copiedId.value = id;
            copied.value = true;
            setTimeout(() => {
                copied.value = false;
                copiedId.value = "";
            }, 1000);
        };

        // handle errors
        watchEffect(() => {
            if (getErr.value) {
                error.value = "Cannot fetch invitations";
            } else if (deleteErr.value) {
                error.value = "Could not delete invitation";
            } else {
                error.value = null;
            }
        });

        const editInvite = (id) => {
            router.push({
                name: "EditInspectorLocation",
                params: { id: id },
            });
        };

        return {
            documents,
            error,
            // formattedDocuments,
            deleteInvite,
            empty,
            access,
            copyUrl,
            copied,
            copySuccess,
            copiedId,
            moment,
            showEdit,
            editInvite,
            inspectorID,
        };
    },
};
</script>

<style lang="scss" scoped>
.table-row {
    .description {
        .invite {
            display: flex;
            justify-content: flex-end;
            color: $rm-grey;
            .copy-icon {
                margin-left: 1em;
            }
            &:hover {
                cursor: pointer;
                color: $rm-pink;
            }
        }
        .isCopied {
            color: $rm-success !important;
            &:hover {
                color: $rm-success !important;
            }
        }
    }
}
.nav-row{
    padding: 0 1.5em;
}
</style>
