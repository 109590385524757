<template>
    <div class="right-nav flex-d">
        <div class="page-title">
            <h2>People</h2>
        </div>
        <div class="page-links" v-if="access">
            <ul class="spacing">
                <li>
                    <router-link :to="{ name: 'PeopleDash' }">Employees</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'InvitationsDash' }">Invitations</router-link>
                </li>
                <!-- <li>
                    <router-link :to="{ name: 'SuspendedDash' }">Suspended</router-link>
                </li>-->
                <li>
                    <router-link :to="{ name: 'InspectorInvitationsDash' }">Inspectors</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "PeopleDashNav",
    props: ["access"],
};
</script>


<style lang="scss">
</style>